import { useInView } from "react-intersection-observer";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";

const PLATFORM = {
  CONNECTED_TV: "Connected TV",
  MOBILE: "Mobile",
  TABLET: "Tablet",
  WEB: "Web",
};
let browseIcon = "./assets/images/browse.svg";

function WatchEveryWhereTab() {
  /*const { ref1, inView1 } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });*/
  // Set up the first div inView tracking
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  const [ref3, inView3] = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  let icoTV = "./assets/images/ico-plattv.svg";
  let icoMobile = "./assets/images/ico-platmobile.svg";
  let icoTablet = "./assets/images/ico-plattablet.svg";
  let icoWeb = "./assets/images/ico-platdesktop.svg";
  let commonImage = "./assets/images/everywhere-bg.svg";
  let tvImage = "./assets/images/_tab-ctv-april.webp";
  let mobileImage = "./assets/images/_tab-mobile-april.webp";
  let tabletImage = "./assets/images/_tab-tablet-april.webp";
  let webImage = "./assets/images/_tab-web-april.webp";

  const [key, setKey] = useState(PLATFORM.CONNECTED_TV);
  const [platform, setPlatform] = useState("tv");

  return (
    <section id="watchEveTab" className="padVerTab">
      <div className="container">
        <div className="row">
          <div
            id="normal-tab"
            className="col-lg-4 d-flex justify-content-center align-items-center text-start"
          >
            <div className="row ">
              <h1>Watch Everywhere You Go!</h1>
              <span className="my-2">Watch Free Movies & TV Shows</span>
              <span className="my-2 available-text">
                {/* {platform != "web" ? "AVAILABLE IN" : ""} */}
              </span>
              <div className="ico-wrapper" ref={ref1}>
                {inView1 ? getIcons(platform) : null}
              </div>
            </div>
          </div>
          <div className="col-lg-8 weverytab" ref={ref3}>
            {inView3 ? (
              <Tabs
                defaultActiveKey="tv"
                id="noanim-tab-example"
                className="mb-3"
                onSelect={(e) => {
                  setPlatform(e);
                }}
              >
                <Tab
                  eventKey="tv"
                  title={getTabTitle(icoTV, "TV")}
                  mountOnEnter
                  unmountOnExit
                >
                  <img src={tvImage} alt="TV" className="tabConImg" />
                  {/* TV */}
                </Tab>
                <Tab
                  eventKey="mobile"
                  title={getTabTitle(icoMobile, "Mobile")}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <img src={mobileImage} alt="Mobile" className="tabConImg" />
                    {/* Mobile */}
                  </div>
                </Tab>
                <Tab
                  eventKey="tab"
                  title={getTabTitle(icoTablet, "Tab")}
                  mountOnEnter
                  unmountOnExit
                >
                  <img src={tabletImage} alt="Tablet" className="tabConImg" />
                </Tab>
                <Tab
                  eventKey="web"
                  title={getTabTitle(icoWeb, "Web")}
                  mountOnEnter
                  unmountOnExit
                >
                  <img src={webImage} alt="Website" className="tabConImg" />
                </Tab>
              </Tabs>
            ) : null}
          </div>
          <div
            id="mobile-tab"
            className="col-lg-4 d-flex justify-content-center align-items-center text-center mt-4 d-none"
          >
            <div className="row ">
              <h1>Watch Everywhere You Go!</h1>
              <span className="my-2">Watch Free Movies & TV Shows</span>
              <span className="my-2 available-text">
                {/* {platform != "web" ? "AVAILABLE IN" : ""} */}
              </span>
              {/* <div className="">{getIcons(platform)}</div> */}
              <div className="ico-wrapper" ref={ref2}>
                {inView2 ? getIcons(platform) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function handleSelect(e) {
  console.log("EE ", e);
}

function getIcons(platform) {
  if (platform == "tv") {
    return (
      <>
        <a
          href="https://channelstore.roku.com/en-gb/details/22e247eb7f697a465ef2d9719e52b495/fawesome"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/ctv/ico-tab-roku.svg"
            alt="TV"
            className="platImg"
          />
        </a>
        <a
          href="https://www.amazon.com/Future-Today-Inc-Movies-Fawesome-tv/dp/B076X8FKXP/s"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/ctv/ico-tab-firetv.svg"
            alt="TV"
            className="platImg"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/fawesome/id1063891742?platform=appleTV"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/ctv/ico-tab-appletv.svg"
            alt="TV"
            className="platImg"
          />
        </a>
        {/* <a
          href="https://www.xfinity.com/apps"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-xfinity.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        {/* <a
          href="https://www.xumo.com/products/xumo-tv"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-xumo.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        {/* <a
          href="https://apps.apple.com/us/app/fawesome/id1063891742?platform=appleTV"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-cox.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        {/* <a
          href="https://www.rogers.com/support/apps"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-rog.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        {/* <a
          href="https://support.shaw.ca/"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-shaw.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        {/* <a
          href="https://www.samsung.com/us/apps/"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-samsung.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        <a
          href="https://us.lgappstv.com/main/tvapp/detail?appId=458741"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/ctv/ico-tab-lg.svg"
            alt="TV"
            className="platImg"
          />
        </a>
        {/* <a
          href="https://www.vizio.com/en/smart-tv-apps"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-vizio.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        {/* <a
          href="https://www.vidaa.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-vida.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        <a
          href="https://apps.microsoft.com/detail/9p55dw09xk2d?amp%3Bgl=US&hl=en-us&gl=US"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/ctv/ico-tab-windows.svg"
            alt="TV"
            className="platImg"
          />
        </a>
        {/* <a
          href="https://www.xbox.com/en-US/entertainment/console-apps"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        > */}
        <img
          src="./assets/images/platform/ctv/ico-tab-xbox.svg"
          alt="TV"
          className="platImg"
        />
        {/* </a> */}
        <a
          href="https://play.google.com/store/search?q=fawesome&c=apps"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/ctv/ico-tab-googletv-3.svg"
            alt="TV"
            className="platImg"
          />
        </a>
      </>
    );
  } else if (platform == "mobile") {
    return (
      <>
        <a
          href="https://play.google.com/store/apps/details?id=com.future.moviesByFawesomeAndroidTV&hl=en_US&gl=US"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/mobile/ico-tab-android.svg"
            alt="Mobile"
            className="platImg"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/fawesome/id1063891742#?platform=iphone"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/mobile/ico-tab-ios.svg"
            alt="Mobile"
            className="platImg"
          />
        </a>
        {/* <a
          href="https://www.amazon.com/Future-Today-Inc-Movies-Fawesome-tv/dp/B076X8FKXP"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/mobile/ico-tab-kindle.svg"
            alt="Mobile"
            className="platImg"
          />
        </a> */}
      </>
    );
  } else if (platform == "tab") {
    return (
      <>
        <a
          href="https://play.google.com/store/apps/details?id=com.future.moviesByFawesomeAndroidTV&hl=en_US&gl=US"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/mobile/ico-tab-android.svg"
            alt="Tablet"
            className="platImg"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/fawesome/id1063891742#?platform=iphone"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/mobile/ico-tab-ios.svg"
            alt="Tablet"
            className="platImg"
          />
        </a>
        <a
          href="https://www.amazon.com/Future-Today-Inc-Movies-Fawesome-tv/dp/B076X8FKXP"
          target="_blank"
          rel="noopener noreferrer"
          className="gtm-lp gtm-cta-store"
        >
          <img
            src="./assets/images/platform/mobile/ico-tab-kindle.svg"
            alt="Tablet"
            className="platImg"
          />
        </a>
      </>
    );
  } else if (platform == "web") {
    return (
      <a
        className="btn btn-primary btn-browse-now gtm-lp gtm-browse-now"
        rel="noopener noreferrer"
        href="./home/"
      >
        <img
          src={browseIcon}
          className="img-fluid"
          style={{ paddingRight: 15 }}
          alt="Fawesome"
          // style="opacity: 1;"
        />
        Watch Now
      </a>
    );
  }
}

function getTabTitle(imgSrc, title) {
  return (
    <>
      <div className="wetabicon">
        <>
          <img src={imgSrc} alt="connected tv" />
          <p className="p-0 d-none">{title}</p>
        </>
      </div>
    </>
  );
}

export default WatchEveryWhereTab;

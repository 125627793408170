import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Share from "./Share";
import DescriptionRenderer from "./DescriptionRenderer";
function BlogDetailsPage(data) {
  let listData = data.data;
  /*const { id } = useParams();
  const [listData, setListData] = useState({});
  useEffect(() => {
    fetch("./assets/data/blogs.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        const getDetails = myJson.filter((val) => val.id == id);
        setListData(getDetails[0]);
      });
  }, []);*/

  return (
    <section className="container blog-posts">
      <div className="row mt-2">
        <div className="blogstuff-de">
          {/* <p>{listData?.description}</p> */}
          {/* <div dangerouslySetInnerHTML={{ __html: listData?.description }} /> */}
          <DescriptionRenderer listData={listData} />
        </div>
        {Object.keys(listData).length &&
        listData.movie_info &&
        listData.movie_info.list &&
        listData.movie_info.list.length > 0 ? (
          <>
            {renderList(listData.movie_info)}
            <div className="m-5"></div>
          </>
        ) : (
          ""
        )}

        {Object.keys(listData).length &&
        listData.show_info &&
        listData.show_info.list &&
        listData.show_info.list.length > 0
          ? renderList(listData.show_info)
          : ""}

        {/* Array Traverse */}
        {Object.keys(listData).length &&
        listData.movie_info_arr &&
        listData.movie_info_arr.length > 0
          ? listData.movie_info_arr.map((item, indx) => {
              return (
                <div className="list-array" key={indx}>
                  {renderList(item)}
                </div>
              );
            })
          : ""}
        {/* Array Traverse */}

        <div className="blogstuff-de">
          <div dangerouslySetInnerHTML={{ __html: listData?.footer_title }} />
        </div>
        <div className="d-flex align-items-center mt-5">
          <a href={listData?.author?.url} target="_blank">
            <img
              style={{ width: "1em", marginBottom: "0" }}
              src="./assets/images/ico-linkedin.svg"
              alt="LinkedIn"
            />
          </a>

          <a
            href={listData?.author?.url}
            target="_blank"
            className="author-link"
          >
            <span className="blog-author" style={{ marginLeft: "0.5em" }}>
              by: {listData?.author?.name}
            </span>
          </a>
        </div>
      </div>
    </section>
  );
  function getDisplayTitle(item) {
    let displayTitle = item.movie_title;
    if (item.movie_display_title) {
      displayTitle = item.movie_display_title;
    }
    return displayTitle;
  }
  function getBlogLaunchUrl(item) {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    let newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    let title = getCleanedTitle(item.movie_title);
    let nodeId = item.node_id;
    let base = "/";
    let itemType = "movies";
    if (item.itemType && item.itemType.toLowerCase() === "tv-shows") {
      itemType = "tv-shows";
    } else if (item.itemType && item.itemType.toLowerCase() === "category") {
      itemType = "category";
    } else if (item.itemType && item.itemType.toLowerCase() === "shows") {
      itemType = "shows";
    }
    //itemType = "tv-shows";
    let moviePath = `${itemType}/${nodeId}/${title}`;
    let moviePathWithSlash = base + moviePath;
    if (!newUrl.endsWith("/")) {
      moviePath = moviePathWithSlash;
    }
    let baseUrl = document.location.origin;
    //window.location.href = baseUrl + moviePath + qs;
    return baseUrl + moviePath + qs;
  }
  function getCleanedTitle(str) {
    str = str.toLowerCase().split(" ").join("-");
    /*// Replace all non-alphanumeric characters except hyphens with an empty string
		str = str.replace(/[^\w-]+/g, '-');
		// Replace consecutive hyphens with a single hyphen
		str = str.replace(/-{2,}/g, '-');
		return str;*/
    // Remove all non-alphanumeric characters except hyphens
    str = str.replace(/[^\w-]/g, "");
    // Remove consecutive hyphens
    str = str.replace(/-{2,}/g, "-");
    // Remove trailing hyphen (if present)
    str = str.replace(/-$/, "");
    return str;
  }
  function getImageUrl(item) {
    let imageUrl = `${window.cdnBaseUrl}${item.movie_image}`;
    return imageUrl;
  }
  function renderList(info) {
    return (
      <>
        <h2
          className="mb-4 list-title"
          dangerouslySetInnerHTML={{ __html: info.title }}
        />
        {info.list.length > 0 &&
          info.list.map((item, index) => {
            return [
              index % 2 === 0 ? (
                <article className="blog-de-post" key={index}>
                  <div className="blog-de-post-container row">
                    <div className="blog-de-post-image col-md-3 col-sm-12">
                      <a
                        href={getBlogLaunchUrl(item)}
                        className="gtm-lp gtm-blog-thumbnail"
                      >
                        <img src={getImageUrl(item)} alt={item.movie_title} />
                      </a>
                    </div>
                    <div className="blog-de-post-content col-md-9 col-sm-12">
                      <div className="blog-de-post-header">
                        <h2>{getDisplayTitle(item)}</h2>
                        {item.movie_sub_title ? (
                          <h2>{item.movie_sub_title}</h2>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="blogstuff-de mb-4 ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.movie_description,
                          }}
                        />
                      </div>
                      <a
                        href={getBlogLaunchUrl(item)}
                        className="btn btn-primary gtm-lp gtm-blog-watch-now"
                      >
                        Watch Now
                      </a>
                      &nbsp;&nbsp;
                      <div className="share-container blog-list">
                        <Share
                          url={getBlogLaunchUrl(item)}
                          msg={"Watch%20Now%20On%20Fawesome"}
                        ></Share>
                      </div>
                    </div>
                  </div>
                </article>
              ) : (
                <article className="blog-de-post" key={index}>
                  <div className="blog-de-post-container row">
                    <div className="blog-de-post-content col-md-9 col-sm-12">
                      <div className="blog-de-post-header">
                        <h2>{getDisplayTitle(item)}</h2>
                        {item.movie_sub_title ? (
                          <h2>{item.movie_sub_title}</h2>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="blogstuff-de mb-4 ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.movie_description,
                          }}
                        />
                      </div>
                      <a
                        href={getBlogLaunchUrl(item)}
                        className="btn btn-primary gtm-lp gtm-blog-watch-now"
                      >
                        Watch Now
                      </a>
                      &nbsp;&nbsp;
                      <div className="share-container blog-list">
                        <Share
                          url={getBlogLaunchUrl(item)}
                          msg={"Watch%20Now%20On%20Fawesome"}
                        ></Share>
                      </div>
                    </div>
                    <div className="blog-de-post-image col-md-3 col-sm-12">
                      <a
                        href={getBlogLaunchUrl(item)}
                        className="gtm-lp gtm-blog-thumbnail"
                      >
                        <img src={getImageUrl(item)} alt={item.movie_title} />
                      </a>
                    </div>
                  </div>
                </article>
              ),
            ];
          })}
      </>
    );
  }
}

export default BlogDetailsPage;
